import React, { useEffect, useState } from "react";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import "./ChatProfile.css";
import { Container, Media } from "reactstrap";
import streamFour from "../../assets/img/dummy.jpg";
import streamFive from "../../assets/img/dummy.jpg";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getReduxPostsById } from "components/redux/actions/postActions";
import { getReduxUserById } from "components/redux/actions/userActions";
import { getUserById } from "components/redux/actions/userActions";
import { getAllUsers } from "components/redux/actions/userActions";
import WidthCheck from "components/WidthCheck";

const ChatProfile = (props) => {
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPortion, setIsOpenPortion] = useState(false);
  const history = useHistory();
  const getUser = useSelector((state) => state?.getUserById);
  const userOriginal = getUser?.userData;
  const values = {
    userId: userId.id,
  };

  let posts = [];
  let sendingId = props?.profileData?._id;
  const user = useSelector((state) =>
    getReduxUserById(state?.getAllUsers?.allUsers, sendingId)
  );
  const userData = user;
  const AllUserPosts = useSelector((state) =>
    getReduxPostsById(state?.getPosts, sendingId)
  );
  AllUserPosts?.map((data) => {
    if (data?.key === "post" && data?.postCheck !== true) {
      posts.push(data);
    }
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserById(values));
    dispatch(getAllUsers());
  }, [userId]);

  const toggleOpenPortion = (val1, val2) => {
    setIsOpenPortion(val2);
    setIsOpen(val1);
  };

  return (
    <>
      <WidthCheck
        handleOpenValue={toggleOpenPortion}
        component="chat-profile"
      />
      <div className={isOpen ? "open d-none" : "profile-chats"}>
        <Media className="mt-4 ml-3 chat-media">
          <Media left>
            <img
              object
              src={
                userOriginal?.profilePic ? userOriginal?.profilePic : streamFive
              }
              alt="jannan"
              className="chat-upper-profile rounded-circle"
            />
          </Media>
          <Media body className="ml-3 mt-1">
            <h4 className="text-white mb-0" style={{ fontWeight: "600" }}>
              {userOriginal?.firstName} {userOriginal?.lastName}
            </h4>
            <p className="chat-designation">
              {userOriginal?.onlineStatus ? "online" : "offline"}
            </p>
          </Media>
        </Media>

        <hr style={{ backgroundColor: "#555555" }} className="mr-3 ml-3" />
        {props?.profileData ? (
          <>
            <Container className="d-flex justify-content-center">
              <img
                object
                src={
                  props?.profileData?.profilePic
                    ? props?.profileData?.profilePic
                    : streamFour
                }
                alt="jannan"
                className="profile-pic rounded-circle mt-3"
                onClick={() => {
                  history.push(`/admin/profile/${props?.profileData?._id}`);
                }}
              />
            </Container>

            <h4
              className="text-white text-center mb-0"
              style={{ fontWeight: "600" }}
              onClick={() => {
                history.push(`/admin/profile/${props?.profileData?._id}`);
              }}
            >
              {userData?.username ? userData?.username : userData?.firstName}
            </h4>
            <p
              className=" mb-0 text-center chat-designation"
              style={{ fontWeight: "700" }}
            >
              {props?.profileData?.onlineStatus ? "online" : "offline"}
            </p>
            <hr style={{ backgroundColor: "#555555" }} className="mr-3 ml-3" />

            <div style={{ display: "flex", justifyContent: "center" }}></div>
            <div className="ml-3">
              <h4
                className="text-white mt-3 mb-0"
                style={{ fontWeight: "600" }}
              >
                About
              </h4>
              <p>
                {props?.profileData?.about
                  ? props?.profileData?.about
                  : "No description!"}
              </p>
              <a href="www.erroxr.com/web/alexrock" style={{ color: "blue" }}>
                {props?.profileData?.website
                  ? "www." + props?.profileData?.website
                  : ""}
              </a>

              <div style={{ display: "flex" }}>
                <AiFillInstagram
                  className="ml-1"
                  style={{ color: "white", fontSize: "20px" }}
                />
                <AiFillFacebook
                  className="ml-1"
                  style={{ color: "white", fontSize: "20px" }}
                />
              </div>
            </div>

            <hr
              style={{ backgroundColor: "#555555", height: "2px" }}
              className="mr-3 ml-1"
            />

            <Row>
              {posts?.map((data) => (
                <Col xl={5}>
                  <img src={data?.postPic} alt="" className="ml-lg-3 mt-2" />
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <h3 className="text-center mt-3">No friends?</h3>
        )}
      </div>
    </>
  );
};

export default ChatProfile;
