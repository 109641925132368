import React from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";
import "./sidebar.css";
import { Nav, NavLink as ReactstrapNavLink } from "reactstrap";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import { Row } from "reactstrap";
import Logo from "../../assets/img/logo5.png";
import apple from "../../assets/img/j4.png";
import playstore from "../../assets/img/j5.png";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiOutlineTwitter,
} from "react-icons/ai";
import AOS from "aos";


function AdminSidebar(props) {
  const location = useLocation();
  const sidebarRef = React.useRef(null);
  
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  const history = useHistory();
  const logOut = () => {
    localStorage.clear();
    history.push("/login");
  };
  AOS.init();

  const { routes, rtlActive } = props;

  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <div className="sidebar user-sidebar ml-0 mt-0">
          <div className="sidebar-wrapper sid-rap" ref={sidebarRef}>
            <div className="sidebar-logo mt-2 ">
              <img src={Logo} alt="" className="" />
            </div>

            <Nav style={{ marginTop: "-5px" }}>
              {routes.map((prop, key) => {
                if (prop.redirect) return null;
                return (
                  <li
                    className={
                      activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                    }
                    key={key}
                  >
                    {prop.name !== "Profile" && (
                      <NavLink
                        to={prop.layout + prop.path}
                        className="nav-link sidebar-links ml-lg-5"
                        activeClassName={
                          prop.name === "Logout" ? "" : "links-active"
                        }
                        onClick={props.toggleSidebar}
                      >
                        <i
                          className={prop.icon}
                          style={{ marginTop: "6px" }}
                        ></i>

                        <p className="links-name">
                          {rtlActive ? prop.rtlName : prop.name}
                        </p>
                      </NavLink>
                    )}
                  </li>
                );
              })}
              <NavLink
                to="/login"
                className="nav-link sidebar-links ml-lg-5"
                onClick={logOut}
              >
                <li>
                  <i className="fa fa-sign-out"></i>

                  <p className="links-name ">Logout</p>
                </li>
              </NavLink>

              <div style={{ marginTop: "100%" }}>
                <p className="mb-2 footer-main ml-4">Download our mobile app</p>
                <Row className="mb-3 ml-3">
                  <img
                    src={apple}
                    className="ml-4"
                    style={{ width: "30%" }}
                    alt=""
                  />
                  <img
                    src={playstore}
                    className="ml-2"
                    style={{ width: "30%" }}
                    alt=""
                  />
                </Row>
                <p className="footer-text text-center ">
                  All right reserved. &copy; Eroxr 2023
                </p>
                <p className=" footer-textt  text-center ">
                  By visting this page you agreeing to our{" "}
                </p>
                <p className=" footer-policy mb-1 ml-2  mr-1 text-center">
                  Privacy Policy and Terms and Conditions
                </p>

                <Row className="justify-content-center">
                  <AiFillInstagram className="footer-icons ml-1" />
                  <AiFillLinkedin className="footer-icons ml-1" />
                  <AiOutlineTwitter className="footer-icons ml-1" />
                  <AiFillFacebook className="footer-icons ml-1" />
                </Row>
              </div>
            </Nav>
          </div>
        </div>
      )}
    </BackgroundColorContext.Consumer>
  );
}

AdminSidebar.propTypes = {
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    text: PropTypes.node,
    imgSrc: PropTypes.string,
  }),
};

export default AdminSidebar;
