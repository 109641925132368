import axios from 'axios'
const createAxiosInstance= () => {
  const jwtToken = JSON.parse(localStorage.getItem("keys"))

  if(!jwtToken) {
    // console.log('JWT Token is null or undefined');
  }
  
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000,
    headers: {
      Authorization: `Bearer ${jwtToken?.token}`,
      
    },
    withCredentials: true,
  });

  return instance;
};

export default  createAxiosInstance 