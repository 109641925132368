// ZegoCloudComponent.js
import React, { useEffect, useRef, useState } from "react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PusherInstance from "Api/Pusher";
import { showSuccessAlert } from "components/Alerts/Alerts";

const ZegoCloudComponent = ({
  role,
  onLiveStart,
  onLiveEnd,
  showPreJoinView,
  turnOnCameraWhenJoining,
  sharedLinks,
}) => {
  const { id } = useParams();
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const getUser = useSelector((state) => state?.getUserById);
  const userData = getUser?.userData;
  const PusherConfig = PusherInstance();
  const elementRef = useRef(null);

  useEffect(() => {
    const pusherChannel = PusherConfig.subscribe(`tip${userId?.id}`);

    pusherChannel.bind("live-tip", (data) => {
      showSuccessAlert(
        `${data?.senderData?.firstName} sent you  ${data?.tip} sek`
      );
    });

    return () => {
      pusherChannel.unsubscribe();
    };
  }, [PusherConfig, id]);

  useEffect(() => {
    const appID = parseInt(process.env.REACT_APP_ZegoCloud_appId, 10);
    const serverSecret = process.env.REACT_APP_ZegoCloud_secret;

    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      id,
      Date.now().toString(),
      userData?.firstName
    );
    const zp = ZegoUIKitPrebuilt.create(kitToken);

    zp.joinRoom({
      container: elementRef.current,
      scenario: {
        mode: ZegoUIKitPrebuilt.LiveStreaming,
        config: {
          role,
        },
      },
      sharedLinks,
      onLiveStart,
      onLiveEnd,
      showPreJoinView,
      turnOnCameraWhenJoining,
    });
  }, [
    id,
    role,
    onLiveStart,
    onLiveEnd,
    showPreJoinView,
    turnOnCameraWhenJoining,
    sharedLinks,
  ]);

  return (
    <div
      ref={elementRef}
      style={{
        height: "100vh",
        width: "100%",
        marginTop: "-80px",
        zIndex: "10000",
      }}
    />
  );
};

export default ZegoCloudComponent;
