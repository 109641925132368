import React, { useState } from "react";
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getPayments } from "components/redux/actions/paymentAction";
import Moment from "react-moment";
import { getAllTip } from "components/redux/actions/paymentAction";
import { getAllSubscriptions } from "components/redux/actions/requestActions";
import { getPaymentsRequest } from "components/redux/actions/paymentAction";
import { updatePaymentRequest } from "Api/Api";
import { showSuccessAlert } from "components/Alerts/Alerts";
import { useHistory } from "react-router-dom";
import { getAllUsers } from "components/redux/actions/userActions";

const Payments = () => {
  const dispatch = useDispatch();
  const [color, setColor] = useState("payments");
  const [pendingPayment, setPendingPayment] = useState([]);
  const [approvedPayment, setApprovedPayment] = useState([]);
  const [declinePayment, setDeclinePayment] = useState([]);
  useEffect(() => {
    dispatch(getPayments());
    dispatch(getAllSubscriptions());
    dispatch(getAllTip());
    dispatch(getPaymentsRequest());
    dispatch(getAllUsers());
  }, [dispatch]);
  const getAllPayment = useSelector((state) => state?.getPaymentReducer);
  const payment = getAllPayment?.payment;
  const getAllSubscription = useSelector(
    (state) => state?.getAllSubscriptionsReducer
  );
  const subscriptions = getAllSubscription?.allSubscriptions;
  const getAllTips = useSelector((state) => state?.getAllTip);
  const tips = getAllTips?.recieverTip;
  const getAllRequest = useSelector((state) => state?.getPaymentRequestReducer);
  const request = getAllRequest?.paymentRequest;

  useEffect(() => {
    setPendingPayment(request.filter((data) => data.status === "pending"));
    setApprovedPayment(request.filter((data) => data.status === "approved"));
    setDeclinePayment(request.filter((data) => data.status === "decline"));
  }, [request, color]);

  const handleColor = (e) => {
    setColor(e);
  };
  const approveRequest = (e) => {
    const values = {
      id: e,
      status: "approved",
    };
    updatePaymentRequest(values).then((res) => {
      if (res?.data?.message === "updated") {
        showSuccessAlert("Status updated")
        setTimeout(() => {
          setPendingPayment(pendingPayment?.filter((data) => data._id !== e));
        }, 2000);
        window.location.reload(false);
      }
    });
  };
  const declineRequest = (e) => {
    const values = {
      id: e,
      status: "decline",
    };
    updatePaymentRequest(values).then((res) => {
      if (res?.data?.message === "updated") {
        showSuccessAlert("Status updated")
        setTimeout(() => {
          setPendingPayment(pendingPayment?.filter((data) => data._id !== e));
        }, 2000);
        window.location.reload(false);
      }
    });
  };

  const history=useHistory()

  return (
    <>
      <div className="content">
        <Row>
          <Col xl={3}>
            <h2 className="ml-lg-3">Payments</h2>
          </Col>
          <Col xl={9} className="">
            <Row className="mt-1 justify-content-end ">
              <h4
                onClick={() => handleColor("payments")}
                className=""
                style={{
                  color: color === "payments" && "white",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
              >
                Payments
              </h4>
              <h4
                onClick={() => handleColor("subscriptions")}
                className="ml-lg-4"
                style={{
                  color: color === "subscriptions" && "white",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
              >
                Subscriptions
              </h4>
              <h4
                onClick={() => handleColor("Tip")}
                className="ml-lg-3"
                style={{
                  color: color === "Tip" && "white",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
              >
                Tips
              </h4>
              <h4
                onClick={() => handleColor("request")}
                className="ml-lg-3"
                style={{
                  color: color === "request" && "white",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
              >
                Requested ({pendingPayment?.length}){" "}
              </h4>
              <h4
                onClick={() => handleColor("approved")}
                className="ml-lg-2"
                style={{
                  color: color === "approved" && "white",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
              >
                Approved ({approvedPayment?.length}){" "}
              </h4>
              <h4
                onClick={() => handleColor("decline")}
                className="ml-lg-2"
                style={{
                  color: color === "decline" && "white",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
              >
                Rejected ({declinePayment?.length}){" "}
              </h4>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                {color === "payments" && (
                  <Table className="tablesorter user-table">
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">Name</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">State</th>
                        <th className="text-center">Payment</th>
                        <th className="text-center">Profile</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payment?.map((data) => (
                        <tr>
                          <td className="text-center">{data?.name}</td>
                          <td className="text-center">
                            {data?.email ? data?.email : "-"}
                          </td>
                          <td className="text-center">{data?.state}</td>
                          <td className="text-center">SEK 100</td>
                          <td className="text-center">
                              <span
                                onClick={() =>
                                  history.push(`/user/profile/${data?.userId}`)
                                }
                                style={{ color: "blue", cursor: "pointer" }}
                              >
                                view profile
                              </span>{" "}
                            </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {color === "subscriptions" && (
                  <Table className="tablesorter user-table">
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">Sender name</th>
                        <th className="text-center">Sender email</th>
                        <th className="text-center">Reciever name</th>
                        <th className="text-center">Reciever email</th>
                        <th className="text-center">payment</th>
                        <th className="text-center">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptions?.map((data) => (
                        <tr>
                          <td className="text-center">
                            {data?.paymentData?.userData?.username
                              ? data?.paymentData?.userData?.username
                              : data?.paymentData?.userData?.firstName}
                          </td>
                          <td className="text-center">
                            {data?.paymentData?.userData?.email}
                          </td>
                          <td className="text-center">
                            {data?.recieverData?.username
                              ? data?.recieverData?.username
                              : data?.recieverData?.firstName}
                          </td>
                          <td className="text-center">
                            {data?.recieverData?.email}
                          </td>
                          <td className="text-center">
                            SEK {data?.paymentData?.payment}
                          </td>
                          <td className="text-center">
                            <Moment format="MM/DD/YYYY">
                              {data?.paymentData?.timestamp}
                            </Moment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {color === "Tip" && (
                  <Table className="tablesorter user-table">
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">Sender name</th>
                        <th className="text-center">Sender email</th>
                        <th className="text-center">Reciever name</th>
                        <th className="text-center">Reciever email</th>
                        <th className="text-center">payment</th>
                        <th className="text-center">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tips?.map((data) => (
                        <tr>
                          <td className="text-center">
                            {data?.paymentData?.senderData?.username
                              ? data?.paymentData?.senderData?.username
                              : data?.paymentData?.senderData?.firstName}
                          </td>
                          <td className="text-center">
                            {data?.paymentData?.senderData?.email}
                          </td>
                          <td className="text-center">
                            {data?.recieverData?.username
                              ? data?.recieverData?.username
                              : data?.recieverData?.firstName}
                          </td>
                          <td className="text-center">
                            {data?.recieverData?.email}
                          </td>
                          <td className="text-center">
                            SEK {data?.paymentData?.tip}
                          </td>
                          <td className="text-center">
                            <Moment format="MM/DD/YYYY">
                              {data?.paymentData?.timestamp}
                            </Moment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {color === "request" && (
                  <Table className="tablesorter user-table">
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">Name</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">Phone number</th>
                        <th className="text-center">Payment</th>
                        <th className="text-center">Date</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendingPayment?.map((data) => (
                        <tr>
                          <td className="text-center">
                            {data?.userData?.username
                              ? data?.userData?.username
                              : data?.userData?.firstName}
                          </td>
                          <td className="text-center">
                            {data?.userData?.email
                              ? data?.userData?.email
                              : "-"}
                          </td>
                          <td className="text-center">
                            {data?.userData?.phoneNumber
                              ? data?.userData?.phoneNumber
                              : "-"}
                          </td>
                          <td className="text-center">SEK {data?.payment}</td>
                          <td className="text-center">
                            <Moment format="MM/DD/YYYY">
                              {data?.timestamp}
                            </Moment>
                          </td>
                          <td className="text-center ">
                            <Button
                              className="reset-button "
                              onClick={() => approveRequest(data?._id)}
                            >
                              Approve
                            </Button>
                            <Button
                              className="cancel-button ml-3"
                              onClick={() => declineRequest(data?._id)}
                            >
                              Decline
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {color === "approved" && (
                  <Table className="tablesorter user-table">
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">Name</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">Phone number</th>
                        <th className="text-center">Payment</th>
                        <th className="text-center">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {approvedPayment?.map((data) => (
                        <tr>
                          <td className="text-center">
                            {data?.userData?.username
                              ? data?.userData?.username
                              : data?.userData?.firstName}
                          </td>
                          <td className="text-center">
                            {data?.userData?.email
                              ? data?.userData?.email
                              : "-"}
                          </td>
                          <td className="text-center">
                            {data?.userData?.phoneNumber
                              ? data?.userData?.phoneNumber
                              : "-"}
                          </td>
                          <td className="text-center">SEK {data?.payment}</td>
                          <td className="text-center">
                            <Moment format="MM/DD/YYYY">
                              {data?.timestamp}
                            </Moment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {color === "decline" && (
                  <Table className="tablesorter user-table">
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">Name</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">Phone number</th>
                        <th className="text-center">Payment</th>
                        <th className="text-center">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {declinePayment?.map((data) => (
                        <tr>
                          <td className="text-center">
                            {data?.userData?.username
                              ? data?.userData?.username
                              : data?.userData?.firstName}
                          </td>
                          <td className="text-center">
                            {data?.userData?.email
                              ? data?.userData?.email
                              : "-"}
                          </td>
                          <td className="text-center">
                            {data?.userData?.phoneNumber
                              ? data?.userData?.phoneNumber
                              : "-"}
                          </td>
                          <td className="text-center">SEK {data?.payment}</td>
                          <td className="text-center">
                            <Moment format="MM/DD/YYYY">
                              {data?.timestamp}
                            </Moment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  );
};

export default Payments;
