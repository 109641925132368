import storage from "Api/firebase";
import { getStorage, ref, uploadBytes,uploadString, getDownloadURL } from "firebase/storage";



export const checkPost = (data,getSubscribedUser,userId) => {
    let matchingImg = null;
    const check = data?.postPic?.includes("video");

    if (check) {
      if (
        getSubscribedUser?.some((datas) => data?.userId === datas?._id) ||
        data?.payerId.includes(userId?.id) ||
        data?.userId === userId?.id
      ) {
        matchingImg = (
          <video
            loop
            muted
            controls
            autoPlay
            src={data?.postPic}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      } else {
        matchingImg = (
          <video
            loop
            muted
            controls
            autoPlay
            src={data?.postPic}
            style={{
              width: "100%",
              height: "100%",
              filter: data?.postCheck === true ? "blur(8px)" : "",
            }}
          />
        );
      }
    } else {
      if (
        getSubscribedUser?.some((datas) => data?.userId === datas?._id) ||
        data?.payerId.includes(userId?.id) ||
        data?.userId === userId?.id
      ) {
        matchingImg = (
          <img
            alt=""
            src={
              data?.postPic
                ? data?.postPic
                : "https://picsum.photos/id/1015/1200/800"
            }
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      } else {
        matchingImg = (
          <img
            alt=""
            src={
              data?.postPic
                ? data?.postPic
                : "https://picsum.photos/id/1015/1200/800"
            }
            style={{
              width: "100%",
              height: "100%",
              filter: data?.postCheck === true ? "blur(12px)" : "",
            }}
          />
        );
      }
    }

    return matchingImg;
};

export const uploadImageToFirebase = async (base64Video) => {
  const fileName = Date.now() + '.jpg';
  const fileRef = ref(storage, fileName);

  try {
    const snapshot = await uploadString(fileRef, base64Video, 'data_url');
    console.log('Uploaded a blob or file!', snapshot);

    // Get the URL of the uploaded image location
    const url = await getDownloadURL(fileRef);
    console.log(url, "Firebase URL");

    return url;
  } catch (error) {
    console.error("Error uploading image to Firebase:", error);
    throw error; // Optionally re-throw the error for handling elsewhere
  }
};

export const uploadBlobImageToFirebase = async (blob) => {
  const fileName = Date.now() + '.jpg';
  const fileRef = ref(storage, fileName);

  try {
    const snapshot = await uploadBytes(fileRef , blob)
    console.log('Uploaded a blob or file!', snapshot);

    // Get the URL of the uploaded image location
    const url = await getDownloadURL(fileRef);
    console.log(url, "Firebase URL");

    return url;
  } catch (error) {
    console.error("Error uploading image to Firebase:", error);
    throw error; // Optionally re-throw the error for handling elsewhere
  }
};

export const uploadBlobVideoToFirebase = async (blob) => {
  const videoRef = 'recorded-videos/' + Date.now();
  const storageRef = ref(storage, videoRef);

  try {
    const snapshot = await uploadBytes(storageRef , blob)
    console.log('Uploaded a blob or file!', snapshot);

    // Get the URL of the uploaded image location
    const url = await getDownloadURL(storageRef);
    console.log(url, "Firebase URL");

    return url;
  } catch (error) {
    console.error("Error uploading image to Firebase:", error);
    throw error; // Optionally re-throw the error for handling elsewhere
  }
};

export const uploadVideoToFirebase = async (base64Video) => {
  const videoRef = ref(storage, 'videos/' + Date.now() + '.mp4');

  try {
    // Upload the base64 video string to Firebase Storage
    const snapshot = await uploadString(videoRef, base64Video, 'data_url');
    console.log('Uploaded a video!', snapshot);

    // Get the download URL of the uploaded video
    const url = await getDownloadURL(videoRef);
    return url
   

    // Use the video URL as needed (e.g., save to state, display to the user, etc.)
  } catch (error) {
    console.error('Error uploading video:', error);
  }
};

export const handleMessage=(msg)=>{
    if(msg?.includes("images")){
      return (
      <span>
        Photo
      </span>
    );
      
    }
    else{
      const words = msg?.trim().split(/\s+/);
     console.log(words,"last message concardination in if")

  if (words?.length > 3) {
     console.log(msg,"last message concardination in if")
    const shortenedText = words.slice(0, 1).join(' ');
    return (
      <span>
        {shortenedText} ...
      </span>
    );
  }

  return <span>{msg}</span>;
    }
}
export const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#6A097D",
            color: "white",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#fce883" },
            "::placeholder": { color: "white" }
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee"
        }
    }
}

//   if (role === ZegoUIKitPrebuilt.Host || role === ZegoUIKitPrebuilt.Cohost) {
//     sharedLinks.push({
//       name: 'Join as co-host',
//       url:
//         window.location.protocol + '//' + 
//         window.location.host + window.location.pathname +
//         '?roomID=' +
//         roomID +
//         '&role=Cohost',
//     });
//   }


    //  window.location.protocol + '//' + 
    //  window.location.host + window.location.pathname +
    //   '?roomID=' +
    //   id +
    //   '&role=Audience',

//     function randomID(len) {
//   let result = "";
//   if (result) return result;
//   var chars = "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP",
//     maxPos = chars.length,
//     i;
//   len = len || 5;
//   for (i = 0; i < len; i++) {
//     result += chars.charAt(Math.floor(Math.random() * maxPos));
//   }
//   return result;
// }

export function truncateAndAddReadMore(inputString, maxLength = 15) {
  if (inputString.length <= maxLength) {
    return inputString;
  }

  const truncatedString = inputString.substring(0, maxLength);
  const readMoreText = <span style={{ color: '#F34191' }}>Read more</span>;
  const resultString = <>{truncatedString} {readMoreText}</>;

  return resultString;
}