import React, { useEffect, useState } from "react";
import { AiOutlineDollar } from "react-icons/ai";
import { Button, Col, Input, Label, Modal, Row } from "reactstrap";
import "./Modals.css";
import memberFour from "../../assets/img/j41.png";
import memberFive from "../../assets/img/j42.png";
import memberSix from "../../assets/img/j43.png";
import memberSeven from "../../assets/img/j44.png";
import memberTen from "../../assets/img/j52.png";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { ToastContainer } from "react-toastify";
import { sendTip } from "Api/Api";
import { useSelector } from "react-redux";
import { showSuccessAlert } from "components/Alerts/Alerts";
import { showErrorAlert } from "components/Alerts/Alerts";
import { CARD_OPTIONS } from "components/DynamicFuntions";
import { ActionAnimation } from "components/LottieAnimation/LottieAnimations";

const PaymentModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [animationCheck, setAnimationCheck] = useState(false);
  const [tip, setTip] = useState(false);
  function toggleModal() {
    setShowModal(!showModal);
  }

  useEffect(() => {
    if (props?.open === true) {
      toggleModal();
    }
  }, [props]);
  const getUser = useSelector((state) => state.getUserById);

  const userData = getUser?.userData;
  const handlePayment = async () => {
    setAnimationCheck(true);
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (!error) {
        try {
          const { id } = paymentMethod;

          const values = {
            senderData: userData,
            recieverId: props?.value?.userData?._id,
            tip: tip,
            paymentId: id,
          };
          sendTip(values).then((res) => {
            if (res.data.message === "payment Successfull") {
              showSuccessAlert("Tip sent");

              setAnimationCheck(false);
              toggleModal();
            } else {
              setAnimationCheck(false);
              showErrorAlert("Server Error");
            }
          });
        } catch (error) {
          setAnimationCheck(false);
          showErrorAlert("Server Error");
        }
      }
    } catch (ex) {
      setAnimationCheck(false);
      showErrorAlert("Server Error");
    }
  };

  return (
    <div className="content">
      <div>
        <AiOutlineDollar
          onClick={toggleModal}
          className=""
          style={{
            color: "white",
            fontSize: "35px",
            cursor: "pointer",
            background: "#1e1e26",
            borderRadius: "0 20px 0 0",
            paddingTop: "10px",
          }}
        />

        <Modal
          isOpen={showModal}
          toggle={toggleModal}
          className="main-modal"
          style={{ maxWidth: "400px", borderRadius: "10px" }}
        >
          <div className="modal-header"></div>
          <div className="modal-body home-modal">
            <Row className="" style={{ marginTop: "-20px" }}>
              <Col className="">
                <h4 className="text-white" style={{ fontWeight: "600" }}>
                  Send Some dollars
                </h4>
              </Col>
            </Row>
            <Row>
              <Col className="">
                <Row className="">
                  <Col>
                    <input
                      type="radio"
                      name="radio-group"
                      className="ml-3"
                      aria-label="Radio button"
                    />
                    <img
                      src={memberTen}
                      className="img-fluid ml-3"
                      alt="stripe"
                    />
                    <span className="mt-2 ml-2" style={{ color: "#8B8B8B" }}>
                      Card
                    </span>
                  </Col>
                  <Col>
                    <img
                      src={memberFour}
                      className="img-fluid ml-3 mt-1 "
                      alt="stripe"
                    />
                    <img
                      src={memberFive}
                      className="img-fluid ml-3 mt-1 "
                      alt="stripe"
                    />
                    <img
                      src={memberSix}
                      className="img-fluid ml-3 mt-1"
                      alt="stripe"
                    />
                    <img
                      src={memberSeven}
                      className="img-fluid ml-3 mt-1"
                      alt="stripe"
                    />
                  </Col>
                </Row>
                <Label
                  for="exampleEmail"
                  className="mt-4"
                  style={{
                    color: "white",
                    fontWeight: "700",
                    fontSize: "15px",
                  }}
                >
                  Tip $
                </Label>
                <Input
                  required
                  type="number"
                  onChange={(e) => setTip(e.target.value)}
                  className="form-control post-input mt-2"
                  placeholder="tip $"
                />
                <Label
                  for="exampleEmail"
                  className="mt-4"
                  style={{
                    color: "white",
                    fontWeight: "700",
                    fontSize: "15px",
                  }}
                >
                  Card number
                </Label>
                <CardElement options={CARD_OPTIONS} className="" />

                {animationCheck ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {ActionAnimation()}
                  </div>
                ) : (
                  <>
                    <h1 className="text-center mt-4 mb-0">
                      <Button
                        className="pay-btn reset-button"
                        onClick={handlePayment}
                      >
                        Submit
                      </Button>
                    </h1>
                    <h1 className="text-center mb-0">
                      <Button
                        type="button"
                        className="pay-cancel-btn"
                        onClick={toggleModal}
                      >
                        Cancel
                      </Button>
                    </h1>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PaymentModal;
