import React, { useEffect, useState } from "react";
import { BsDot, BsFillBellFill } from "react-icons/bs";
import "./ChatPortion.css";
import { Media } from "reactstrap";
import streamFour from "../../assets/img/dummy.jpg";
import streamFive from "../../assets/img/dummy.jpg";
import { Badge } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getLastMessage } from "Api/Api";
import { updateAllCallStatus } from "Api/Api";
import { getCallById } from "components/redux/actions/callActions";
import { ToastContainer } from "react-toastify";
import { updateNotiStatus } from "Api/Api";
import { getContactById } from "components/redux/actions/contactActions";
import { getRequestBySenderId } from "components/redux/actions/requestActions";
import { updateTipNotiStatus } from "Api/Api";
import { handleMessage } from "components/DynamicFuntions";
import Notifications from "components/Notifications/Notifications";
import { getReduxSubscribedUser } from "components/redux/actions/paymentAction";
import WidthCheck from "components/WidthCheck";

const ChatPortion = () => {
  const [notiLen, setNotiLen] = useState(0);
  const [notiOpen, setNotiOpen] = useState(false);
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPortion, setIsOpenPortion] = useState(false);
  const [tipUsers, setTipUsers] = useState([]);
  const [lastMessages, setLastMessages] = useState();
  const [subscribedByReciever, setSubscribedByReciever] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const getCalls = useSelector((state) => state?.getAllCallReducer?.call);
  const AllTipUsers = useSelector((state) =>
    getReduxSubscribedUser(state?.getAllTip, userId?.id)
  );
  const getAllAcceptedRequests = useSelector(
    (state) => state?.getContactById?.contactById
  );
  const subscribedUsers = useSelector(
    (state) => state?.getAllSenderRequestReducer
  );
  const subscribedData = subscribedUsers?.senderAllRequests;
  const getUser = useSelector((state) => state.getUserById);
  const userData = getUser?.userData;
  let readChats = getAllAcceptedRequests;
  useEffect(() => {
    setTipUsers(
      AllTipUsers?.filter((data) => data?.paymentData?.notiStatus === true)
    );
  }, []);
  useEffect(() => {
    setSubscribedByReciever(
      subscribedData?.filter((data) => data?.paymentData?.notiStatus === true)
    );
  }, [subscribedData]);

  useEffect(() => {
    setNotiLen(
      (subscribedByReciever?.length || 0) +
        (getCalls?.length || 0) +
        (tipUsers?.length || 0)
    );
  }, [subscribedByReciever, getCalls, tipUsers]);

  useEffect(() => {
    dispatch(getContactById(values));
    dispatch(getRequestBySenderId(values));
    dispatch(getCallById(values));
  }, []);

  let arrayforLastMessage = [];
  readChats?.map((datass) => {
    arrayforLastMessage.push({
      senderId: userId.id,
      recieverId: datass?._id,
    });
  });

  useEffect(() => {
    getLastMessage(arrayforLastMessage).then((res) => {
      setLastMessages(res?.data?.data);
    });
  }, []);

  const values = {
    userId: userId.id,
  };

  const handleNotiValues = () => {
    setNotiOpen(!notiOpen);
    setNotiLen(0);
    const callValues = {
      userId: userId.id,
    };
    updateAllCallStatus(callValues);
    updateNotiStatus(callValues);
    updateTipNotiStatus(callValues);
  };

  const toggleOpenPortion = (val1, val2) => {
    setIsOpenPortion(val2);
    setIsOpen(val1);
  };

  return (
    <>
      <Notifications notiOpen={notiOpen} />

      <WidthCheck
        handleOpenValue={toggleOpenPortion}
        component="chat-portion"
      />

      <div className={isOpen ? "open d-none" : "home-chats"}>
        <Media className={isOpen ? "open" : "mt-4 ml-3 chat-media"}>
          <Media left>
            <img
              object
              src={userData?.profilePic ? userData?.profilePic : streamFive}
              alt="jannan"
              style={{
                border: userData?.onlineStatus ? "4px solid green" : "",
              }}
              className="upper-profile rounded-circle"
            />
          </Media>
          <Media body className="ml-3 mt-3">
            <h3 className="text-white mb-0" style={{ fontWeight: "600" }}>
              {userData?.firstName} {userData?.lastName}
            </h3>
            <p className="chat-designation">
              {userData?.onlineStatus ? "online" : "offline"}
            </p>
          </Media>
          <Media right onClick={handleNotiValues} style={{ cursor: "pointer" }}>
            <Media body className="ml-3 mt-3">
              <BsFillBellFill
                className="mt-3 "
                style={{
                  marginLeft: "-100%",
                  color: "white",
                  fontSize: "25px",
                }}
              />
              {notiLen > 0 && (
                <Badge
                  onClick={handleNotiValues}
                  style={{
                    color: "white",
                    backgroundColor: "red",
                    marginLeft: "-10px",
                    cursor: "pointer",
                  }}
                  pill
                  className="position-absolute mt-3 top-0 end-0"
                >
                  {notiLen}
                </Badge>
              )}
            </Media>
          </Media>
        </Media>

        <h2 className="home-title ml-3 mt-3 mb-0">Recent Chats</h2>
        <hr style={{ backgroundColor: "#555555" }} className="mr-3 ml-3" />
        <div className={isOpen ? "open" : "chat-div"}>
          {readChats?.map((datass, index) => (
            <div key={index}>
              <div
                style={{
                  cursor: "pointer",
                  position: "relative",
                  display: "flex",
                  alignItems: "flex-start", // Align items at the top
                }}
                onClick={() => history.push("/admin/chat")}
              >
                <div style={{ position: "relative", marginRight: "15px" }}>
                  <img
                    src={datass?.profilePic ? datass?.profilePic : streamFour}
                    className="rounded-circle chat-img mb-4"
                    alt=""
                    onClick={() => history.push("/admin/chat")}
                  />
                  {datass?.onlineStatus === true && (
                    <span style={{ position: "absolute", right: 5, top: 5 }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "0.7em",
                          height: "0.7em",
                          borderRadius: "50%",
                          backgroundColor: "green",
                        }}
                      ></span>
                    </span>
                  )}
                </div>
                <div className="media-body">
                  <p className="mb-1 mt-2 chat-text-inner">
                    {datass?.firstName}
                  </p>
                  {lastMessages?.map((msg) => (
                    <>
                      {msg?.senderId === datass?._id && (
                        <p
                          className="chat-designation"
                          style={{
                            fontSize: "16px",
                            fontWeight: msg?.readStatus === false ? "700" : "",
                          }}
                        >
                          {handleMessage(msg?.message)}
                          {msg?.recieverId === userId?.id &&
                            msg?.readStatus === false && (
                              <span className="ml-3">
                                <BsDot
                                  style={{
                                    fontSize: "30px",
                                    color: "red",
                                    fontWeight: "700",
                                    height: "30px",
                                    width: "30px",
                                  }}
                                />
                              </span>
                            )}
                        </p>
                      )}

                      {msg?.recieverId === datass?._id && (
                        <p
                          className="chat-designation"
                          style={{
                            fontSize: "16px",
                            fontWeight: msg?.readStatus === false ? "700" : "",
                          }}
                        >
                          {handleMessage(msg?.message)}
                          {msg?.recieverId === userId?.id &&
                            msg?.readStatus === false && (
                              <span className="ml-3">
                                <BsDot
                                  style={{
                                    fontSize: "30px",
                                    color: "red",
                                    fontWeight: "700",
                                    height: "30px",
                                    width: "30px",
                                  }}
                                />
                              </span>
                            )}
                        </p>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default ChatPortion;
