import * as actionTypes from '../constants';
import createAxiosInstance from 'Api/ApiInstance';
const api = createAxiosInstance();
// const url ="https://zany-lime-gharial-tam.cyclic.app/"
const url = 'http://localhost:5000';


export const getListById = (values) => async (dispatch) => {
    try {
        const { data } = await api.post(`${url}/getListById`,values);
        dispatch({ type: actionTypes.GET_LIST_SUCCESS, payload: data.data });
        console.log(data,"=========>data-list-redux")

    } catch (error) {
        dispatch({ type: actionTypes.GET_LIST_FAIL, payload: error.response });
    }
};
export const deleteListById = (values) => async (dispatch) => {
    try {
        console.log(values,"------------->redux values")
        
        dispatch({ type: actionTypes.DELETE_LIST_SUCCESS, payload: values });
       

    } catch (error) {
        dispatch({ type: actionTypes.DELETE_LIST_FAIL, payload: error.response });
    }
};