import * as actionTypes from '../constants';
import createAxiosInstance from 'Api/ApiInstance';
const api = createAxiosInstance();
// const url ="https://zany-lime-gharial-tam.cyclic.app/"
const url = 'http://localhost:5000';



export const getAds = () => async (dispatch) => {
    try {
        const { data } = await api.get(`${url}/getAllAd`);
        dispatch({ type: actionTypes.GET_Ads_SUCCESS, payload: data.data });
        console.log(data,"=========>data-redux")

    } catch (error) {
        dispatch({ type: actionTypes.GET_Ads_FAIL, payload: error.response });
    }
};