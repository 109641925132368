import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";
import "./sidebar.css";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavLink as ReactstrapNavLink,
} from "reactstrap";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import { Row } from "reactstrap";
import Logo from "../../assets/img/logo5.png";
import apple from "../../assets/img/j4.png";
import playstore from "../../assets/img/j5.png";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillSetting,
  AiOutlineTwitter,
  AiOutlineUnlock,
  AiTwotoneSecurityScan,
} from "react-icons/ai";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { MdManageAccounts, MdPrivacyTip } from "react-icons/md";
import { BsDot } from "react-icons/bs";
import AOS from "aos";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

function Sidebar(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openList, setOpenList] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const location = useLocation();
  const sidebarRef = React.useRef(null);
  const [sessionTimeout, setSessionTimeout] = useState(null);
  const getSubscribedUser = useSelector(
    (state) => state?.getAllAcceptedRequestReducer?.accpetedRequests
  );
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  const history = useHistory();
  const logOut = () => {
    localStorage.clear();
    history.push("/login");
  };
  AOS.init();

  const { routes, rtlActive } = props;

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("keys"));
    if (userId?.id) {
      startSessionTimeout();
    }
  }, []);

  const startSessionTimeout = () => {
    if (sessionTimeout) {
      clearTimeout(sessionTimeout);
    }
    const timeout = setTimeout(() => {
      const timeoutPrompt = setTimeout(() => {
        expireSession();
      }, 30000); // 30 seconds

      clearTimeout(timeoutPrompt);
      Swal.fire({
        title: "Session Alert",
        text: "Your session is going to expire in 30 seconds Do you want to extend the session?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
        timer: 30000,
        customClass: {
          container: "dark-theme-alert",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          startSessionTimeout();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          expireSession();
        } else if (result.dismiss === Swal.DismissReason.timer) {
          expireSession();
        }
      });
    }, 3600000); // 15 seconds

    setSessionTimeout(timeout);
  };

  const expireSession = () => {
    clearTimeout(sessionTimeout);
    localStorage.clear();
    history.push("/login");
  };

  const shouldRenderSidebar = () => {
    const hiddenPages = ["/admin/live", "/admin/chat"]; // Add more pages if needed
    return !hiddenPages.includes(location.pathname);
  };

  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <div
          className={`sidebar user-sidebar ml-0 mt-0 ${
            shouldRenderSidebar() ? "" : "d-none"
          }`}
        >
          <div className="sidebar-wrapper sid-rap" ref={sidebarRef}>
            <div className="sidebar-logo mt-2 ">
              <img
                src={Logo}
                alt=""
                className=""
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/admin/home")}
              />
            </div>

            <Nav style={{ marginTop: "-5px" }}>
              {routes.map((prop, key) => {
                if (prop.redirect) return null;
                return (
                  <li
                    className={
                      activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                    }
                    key={key}
                  >
                    {prop.name !== "Authentication" &&
                      prop.name !== "PasswordReset" &&
                      prop.name !== "AccountManagement" &&
                      prop.name !== "privacy" &&
                      prop.name !== "Login" &&
                      prop.name !== "Register" &&
                      prop.name !== "createPost" &&
                      prop.name !== "createAd" &&
                      prop.name !== "createPoll" &&
                      prop.name !== "AdDescription" &&
                      prop.name !== "editProfile" &&
                      prop.name !== "ChatCall" &&
                      prop.name !== "ChatVideoCall" &&
                      prop.name !== "Live Streaming" &&
                      prop.name !== "Wishlist" &&
                      prop.name !== "Simulation" && (
                        <NavLink
                          to={prop.layout + prop.path}
                          className="nav-link sidebar-links ml-lg-5"
                          activeClassName={
                            prop.name === "Settings" ||
                            prop.name === "Subscription" ||
                            prop.name === "Live"
                              ? ""
                              : "links-active"
                          }
                          onClick={
                            prop.name === "Settings"
                              ? toggle
                              : props.toggleSidebar
                          }
                        >
                          <i
                            className={prop.icon}
                            style={{ marginTop: "6px" }}
                          ></i>

                          <p
                            onClick={
                              prop.name === "Subscription"
                                ? () => setOpenList(!openList)
                                : () => setOpenList(false)
                            }
                            className={
                              prop.name === "Home"
                                ? "links-name links-home-name"
                                : "links-name"
                            }
                          >
                            {rtlActive ? prop.rtlName : prop.name}
                            {prop.name === "Subscription" &&
                              openList === false && (
                                <IoMdArrowDropdown
                                  className="ml-2"
                                  style={{ fontSize: "30px" }}
                                />
                              )}
                            {prop.name === "Subscription" &&
                              openList === true && (
                                <IoMdArrowDropup
                                  className="ml-2"
                                  style={{ fontSize: "30px" }}
                                />
                              )}
                            {prop.name === "Subscription" &&
                              openList === true && (
                                <div
                                  className="ml-3 mt-1 accordian-div"
                                  data-aos="fade-right"
                                >
                                  <p className="accordian-text">
                                    <span className="accordian-dot">
                                      <BsDot
                                        className="accordian-icon"
                                        style={{ color: "" }}
                                      />
                                    </span>{" "}
                                    All ({getSubscribedUser?.length})
                                  </p>
                                  <p className="accordian-text">
                                    <span className="accordian-dot">
                                      <BsDot
                                        className="accordian-icon"
                                        style={{ color: "green" }}
                                      />
                                    </span>{" "}
                                    Active ({getSubscribedUser?.filter((data)=>data?.active===true)?.length})
                                  </p>
                                  <p className="accordian-text">
                                    <span className="accordian-dot">
                                      <BsDot
                                        className="accordian-icon"
                                        style={{ color: "red" }}
                                      />
                                    </span>{" "}
                                    Expired ({getSubscribedUser?.filter((data)=>data?.active===false)?.length})
                                  </p>
                                  {
                                  // <p className="accordian-text">
                                  //   <span className="accordian-dot">
                                  //     <BsDot
                                  //       className="accordian-icon"
                                  //       style={{ color: "yellow" }}
                                  //     />
                                  //   </span>{" "}
                                  //   Attention Required
                                  // </p>
                                  }
                                </div>
                              )}

                            {prop.name === "Settings" && (
                              <Dropdown
                                isOpen={dropdownOpen}
                                toggle={toggle}
                                className=""
                                style={{ position: "absolute" }}
                              >
                                <DropdownToggle
                                  onClick={(e) => e.preventDefault()}
                                  className="dropDown-sidebar"
                                >
                                  <IoMdArrowDropdown
                                    onClick={toggle}
                                    className=""
                                    style={{ fontSize: "30px" }}
                                  />
                                </DropdownToggle>
                                <DropdownMenu
                                  style={{
                                    backgroundColor: "#161616",
                                    marginLeft: "400%",
                                    marginTop: "-90%",
                                    borderRadius: "20px",
                                  }}
                                >
                                  <DropdownItem className="drop-item" header>
                                    <span className="mr-2  drop-icons">
                                      <AiFillSetting />
                                    </span>
                                    Settngs and Privacy
                                  </DropdownItem>

                                  <DropdownItem
                                    className="drop-item"
                                    header
                                    onClick={() =>
                                      history.push("/admin/authentication")
                                    }
                                  >
                                    <span
                                      className="mr-2 drop-icons"
                                      onClick={() =>
                                        history.push("/admin/authentication")
                                      }
                                    >
                                      <AiTwotoneSecurityScan />
                                    </span>
                                    <span
                                      onClick={() =>
                                        history.push("/admin/authentication")
                                      }
                                    >
                                      Two Factor Authentication
                                    </span>
                                  </DropdownItem>
                                  <DropdownItem className="drop-item" header>
                                    <span className="mr-2  drop-icons">
                                      <MdManageAccounts />
                                    </span>
                                    <span
                                      onClick={() =>
                                        history.push("/admin/management")
                                      }
                                    >
                                      Account Management
                                    </span>
                                  </DropdownItem>
                                  <DropdownItem className="drop-item" header>
                                    <span className="mr-2  drop-icons">
                                      <AiOutlineUnlock />
                                    </span>
                                    <span
                                      onClick={() =>
                                        history.push("/admin/reset")
                                      }
                                    >
                                      Password
                                    </span>
                                  </DropdownItem>
                                  <DropdownItem className="drop-item" header>
                                    <span className="mr-2  drop-icons">
                                      <MdPrivacyTip />
                                    </span>
                                    <span
                                      onClick={() =>
                                        history.push("/admin/privacy")
                                      }
                                    >
                                      Privacy Policy
                                    </span>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            )}
                          </p>
                        </NavLink>
                      )}
                  </li>
                );
              })}
              <NavLink
                to="/login"
                className="nav-link sidebar-links ml-lg-5"
                onClick={logOut}
              >
                <li>
                  <i className="fa fa-sign-out"></i>

                  <p className="links-name ">Logout</p>
                </li>
              </NavLink>
              <div style={{ marginTop: "30px" }} className="mb-5">
                <p className="mb-2 footer-main ml-4">Download our mobile app</p>
                <Row className="mb-3 ml-3">
                  <img
                    src={apple}
                    className="ml-2"
                    alt=""
                    style={{ width: "40%" }}
                  />
                  <img
                    src={playstore}
                    className="ml-2"
                    alt=""
                    style={{ width: "40%" }}
                  />
                </Row>
                <p className="footer-text text-center ">
                  All right reserved. &copy; Eroxr 2023
                </p>
                <p className=" footer-textt  text-center ">
                  By visting this page you agreeing to our{" "}
                </p>
                <p className=" footer-policy mb-1 ml-2  mr-1 text-center">
                  Privacy Policy and Terms and Conditions
                </p>

                <Row className="justify-content-center ">
                  <AiFillInstagram className="footer-icons ml-1" />
                  <AiFillLinkedin className="footer-icons ml-1" />
                  <AiOutlineTwitter className="footer-icons ml-1" />
                  <AiFillFacebook className="footer-icons ml-1" />
                </Row>
              </div>
            </Nav>
          </div>
        </div>
      )}
    </BackgroundColorContext.Consumer>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    text: PropTypes.node,
    imgSrc: PropTypes.string,
  }),
};

export default Sidebar;
