import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "reactstrap";
import "./VerifyAccounts.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllCreatorRequest } from "components/redux/actions/creatorActions";
import { changeCreatorStatus } from "Api/Api";
import {  ToastContainer } from "react-toastify";
import { showSuccessAlert } from "components/Alerts/Alerts";

const VerifyAccounts = () => {
  const [request, setRequest] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCreatorRequest());
  }, [dispatch]);

  const getAllUser = useSelector((state) => state?.getAllCreatorRequest);
  const AllRequest = getAllUser?.creatorRequest;
  useEffect(() => {
    setRequest(AllRequest?.filter((data) => data?.status === "pending"));
  }, [AllRequest]);

  const changeVideoStatus = async (e) => {
    const values = {
      id: e,
      status: "approved",
    };
    await changeCreatorStatus(values).then((res) => {
      console.log(res, "status updated");
      if (res?.data?.message === "updated") {
        showSuccessAlert("Status updated");
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      }
    });
  };
  const changeVideoStatusTwo = async (e) => {
    const values = {
      id: e,
      status: "rejected",
    };
    await changeCreatorStatus(values).then((res) => {
      if (res?.data?.message === "status updated") {
        showSuccessAlert("Status updated");
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      }
    });
  };

  return (
    <>
      <div className="content">
        {request?.length ? (
          <>
            <h2 className="ml-lg-3">Verify Accounts</h2>
            <Row>
              {request?.map((data) => (
                <Col xl={12}>
                  <Row className="mt-1">
                    <Col xl={6}>
                      <Card className="card-chart">
                        <h4 className="ml-lg-3 mt-3">
                          {data?.userData?.firstName} {data?.userData?.lastName}
                        </h4>
                        <video
                          controls
                          muted
                          className="ml-lg-2 mr-lg-2 verify-video"
                          src={data?.videoUrl}
                        />

                        <Row className="mt-3">
                          <Col>
                            <img
                              src={data?.cnicFront}
                              className="ml-lg-2"
                              alt=""
                            />
                          </Col>
                          <Col className="mr-lg-2">
                            <img src={data?.cnicBack} className="" alt="" />
                          </Col>
                        </Row>

                        <Button
                          className="video-accept-btn ml-lg-3 mr-lg-3 mt-3"
                          onClick={() => changeVideoStatus(data?._id)}
                        >
                          Accept
                        </Button>
                        <Button
                          className="video-reject-btn ml-lg-3 mr-lg-3 mb-4"
                          onClick={() => changeVideoStatusTwo(data?._id)}
                        >
                          Reject
                        </Button>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <Col xl={12}>
            <Row className="mt-1">
              <Col xl={6}>
                <Card className="card-chart">
                  <h4 className="ml-lg-3 mt-3">First name Last name</h4>
                  <video
                    controls
                    muted
                    className="ml-lg-2 mr-lg-2 verify-video"
                    src="https://picsum.photos/318/180"
                  />

                  <Row className="mt-3">
                    <Col>
                      <img
                        src="https://picsum.photos/318/180"
                        className="ml-lg-2"
                        alt=""
                      />
                    </Col>
                    <Col className="mr-lg-2">
                      <img
                        src="https://picsum.photos/318/180"
                        className=""
                        alt=""
                      />
                    </Col>
                  </Row>

                  <Button className="video-accept-btn ml-lg-3 mr-lg-3 mt-3">
                    Accept
                  </Button>
                  <Button className="video-reject-btn ml-lg-3 mr-lg-3 mb-4">
                    Reject
                  </Button>
                </Card>
              </Col>
            </Row>
          </Col>
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default VerifyAccounts;
