import * as actionTypes from '../constants';
import createAxiosInstance from 'Api/ApiInstance';
const api = createAxiosInstance();
// const url ="https://zany-lime-gharial-tam.cyclic.app/"
const url = 'http://localhost:5000';


export const getCallById = (values) => async (dispatch) => {
    try {
        const { data } = await api.post(`${url}/getCallById`,values);
        dispatch({ type: actionTypes.GET_ALL_CALL_SUCCESS, payload: data.data });
        console.log(data,"=========>CALL DATA")

    } catch (error) {
        dispatch({ type: actionTypes.GET_ALL_CALL_FAIL, payload: error.response });
    }
};