import React from "react";
import { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useHistory } from "react-router-dom";
import { Button, Card, Col, FormGroup, Input, Label, Row } from "reactstrap";
import memberOne from "../../assets/img/j38.png";
import memberTwo from "../../assets/img/j39.png";
import FileBase64 from "react-file-base64";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MembershipSection from "./MembershipSection";
import "./Memebrship.css";
import { FaHandPointRight } from "react-icons/fa";
import VideoModal from "components/Modals/VideoModal";
import { useDispatch, useSelector } from "react-redux";
import { getReduxCreatorById } from "components/redux/actions/creatorActions";
import { useEffect } from "react";
import { getAllCreatorRequest } from "components/redux/actions/creatorActions";
import { Elements } from "@stripe/react-stripe-js";
import { applyForCreator } from "Api/Api";
import { getUserById } from "components/redux/actions/userActions";
import EroxrFeeModal from "components/Modals/EroxrFeeModal";
import stripePromise from "Api/Stripe";
import { uploadImageToFirebase } from "components/DynamicFuntions";
import { showSuccessAlert } from "components/Alerts/Alerts";
import { showErrorAlert } from "components/Alerts/Alerts";
import { ActionAnimation } from "components/LottieAnimation/LottieAnimations";

function Membership() {
  const [step, setStep] = useState(true);
  const [cnicFront, setCnicFront] = useState();
  const [cnicBack, setCnicBack] = useState();
  const [checkCnic, setCheckCnic] = useState(false);
  const [checkCnicTwo, setCheckCnicTwo] = useState(false);
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [videoUrl, setVideoUrl] = useState(null);
  const [animationCheck, setAnimationCheck] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [frontUrl, setFrontUrl] = useState();
  const [backUrl, setBackUrl] = useState();
  const history = useHistory();
  const creator = useSelector((state) =>
    getReduxCreatorById(state?.getAllCreatorRequest, userId?.id)
  );
  const getUser = useSelector((state) => state.getUserById);
  const userData = getUser?.userData;
  const handleCnicFrontPic = async (e) => {
    setCnicFront(e.selectedFile.base64);
    setCheckCnic(true);
    const result = await uploadImageToFirebase(e.selectedFile.base64);
    if (result) {
      setFrontUrl(result);
    }
  };
  const values = {
    userId: userId.id,
  };

  const handleCnicBackPic = async (e) => {
    setCnicBack(e.selectedFile.base64);
    setCheckCnicTwo(true);
    const result = await uploadImageToFirebase(e.selectedFile.base64);
    if (result) {
      setBackUrl(result);
    }
  };

  const handleVideoValue = (data) => {
    setVideoUrl(data);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserById(values));
    dispatch(getAllCreatorRequest());
  }, [dispatch]);

  const verifyCnic = async () => {
    setAnimationCheck(true);

    if (checkCnic && checkCnicTwo) {
      const values = {
        userId: userId.id,
        videoUrl: videoUrl,
        userData: userData,
        cnicFront: frontUrl,
        cnicBack: backUrl,
      };
      applyForCreator(values).then((res) => {
        if (res.data.message === "applied") {
          showSuccessAlert(
            "you application is pending, it will be approved in 1-3 business days"
          );

          setTimeout(() => {
            history.push("/admin/home");
          }, 3000);
        }
      });
    } else {
      showErrorAlert("Please verify Cnic");
      setAnimationCheck(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="content ">
      <Elements stripe={stripePromise} className="">
        <EroxrFeeModal isOpen={showModal} toggle={closeModal} />
      </Elements>

      <span className="" style={{ color: "white", fontSize: "10px" }}>
        <Link
          to="/admin/home"
          style={{
            color: "white",
            fontSize: "40px",
            marginTop: "-4%",
            position: "absolute",
          }}
        >
          <IoMdArrowRoundBack />
        </Link>
      </span>
      <Row className="">
        {userData?.eroxrFee === true && step === true && (
          <Col xl={11} className="ml-lg-5">
            <Row className="">
              <Col className="text-center">
                <img
                  src={memberOne}
                  style={{ color: "white", width: "60px", marginTop: "-20px" }}
                  alt="cnic"
                />
                <h3
                  className="text-white mb-0 mt-3"
                  style={{ fontWeight: "600" }}
                >
                  Become A Content Creator
                </h3>
                <p className="" style={{ color: "grey", fontSize: "12px" }}>
                  If we notice an attempted login from a device or browser we
                  don't
                  <br /> recognize, we'll ask for your password and a
                  verification code.
                </p>
              </Col>
            </Row>
            <hr style={{ backgroundColor: "#555555" }} className="mr-3 ml-3" />
            {userData?.creator !== true &&
              creator?.status !== "pending" &&
              creator?.status !== "approved" && (
                <FormGroup check className="mt-4">
                  <Input type="radio" className="mt-2" />{" "}
                  <Label
                    style={{
                      color: "white",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    <span>
                      <FaHandPointRight
                        style={{ fontSize: "20px" }}
                        className="mr-4"
                      />
                    </span>
                    Verify Your Self
                  </Label>
                </FormGroup>
              )}
            <Row className="justify-content-center">
              {creator ? (
                <>
                  {creator?.status === "pending" && (
                    <h3>Your request is pending for approval!</h3>
                  )}

                  {userData?.creator === true && (
                    <h3>Your are now a member of eroxr!</h3>
                  )}
                </>
              ) : videoUrl ? (
                <h3>Video uploaded!</h3>
              ) : (
                <VideoModal dataVideoValue={handleVideoValue} />
              )}
            </Row>
            {creator?.status !== "pending" &&
              creator?.status !== "approved" && (
                <>
                  <FormGroup check className="mt-4">
                    <Input type="radio" className="mt-2" />{" "}
                    <Label
                      style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      <span>
                        <FaHandPointRight
                          style={{ fontSize: "20px" }}
                          className="mr-4"
                        />
                      </span>
                      Verify CNIC
                    </Label>
                  </FormGroup>

                  <Row>
                    <Col xl={5} sm={8} md={8}>
                      <Card className="member-card  mt-2">
                        <h4 className="text-center mb-0">
                          <div
                            style={{
                              opacity: "0",
                              position: "absolute",
                              zIndex: "10",
                              marginTop: "15%",
                            }}
                          >
                            <FileBase64
                              type="file"
                              className="text-center"
                              onDone={(base64) =>
                                handleCnicFrontPic({ selectedFile: base64 })
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                          <img
                            src={cnicFront ? cnicFront : memberTwo}
                            style={{ width: "50%", height: "80%" }}
                            alt="cnic"
                            className="ml-2 mr-2 mt-4 mb-2"
                          />
                        </h4>
                        <p
                          className="text-center mt-2 mb-1"
                          style={{ color: " #BFB8B8" }}
                        >
                          Front Copy
                        </p>
                      </Card>
                    </Col>

                    <Col xl={5} sm={5} md={6}>
                      <Card className="member-card mt-2 ml-md-5 mb-0">
                        <h4 className="text-center mb-0">
                          <div
                            style={{
                              opacity: "0",
                              position: "absolute",
                              zIndex: "10",
                              marginTop: "15%",
                            }}
                          >
                            <FileBase64
                              type="file"
                              className="text-center"
                              onDone={(base64) =>
                                handleCnicBackPic({ selectedFile: base64 })
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                          <img
                            src={cnicBack ? cnicBack : memberTwo}
                            alt="cnic"
                            style={{ width: "50%", height: "80%" }}
                            className="ml-2 mr-2 mt-4 mb-2"
                          />
                        </h4>
                        <p
                          className="text-center mt-2 mb-1"
                          style={{ color: " #BFB8B8" }}
                        >
                          Back Copy
                        </p>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}

            {userData?.creator === false &&
              creator?.status !== "pending" &&
              creator?.status !== "approved" && (
                <>
                  <Row>
                    <Col className="text-right">
                      {animationCheck ? (
                        ActionAnimation()
                      ) : (
                        <Button className="reset-button " onClick={verifyCnic}>
                          submit
                        </Button>
                      )}
                    </Col>
                  </Row>
                </>
              )}
          </Col>
        )}
        {userData?.eroxrFee === false && step === true && (
          <Col xl={10} className="">
            <Row className="justify-content-center mt-5">
              FeeAnimation()
            </Row>
            <h1 className="text-center">
              <Button
                type="submit"
                onClick={() => setShowModal(true)}
                className="reset-button mr-2"
                style={{ paddingLeft: "200px", paddingRight: "210px" }}
              >
                Buy our MemberShip!
              </Button>
            </h1>
          </Col>
        )}
{
        // <Col xl={2}>
        //   <MembershipSection />
        // </Col>
}
      </Row>
      <ToastContainer />
    </div>
  );
}

export default function WrappedMemberShip() {
  return (
    <Elements stripe={stripePromise} className="">
      <Membership />
    </Elements>
  );
}
