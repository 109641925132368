import React, { useEffect, useState } from "react";
import { Button, Col, Label, Modal, Row } from "reactstrap";
import "./Modals.css";
import memberFour from "../../assets/img/j41.png";
import memberFive from "../../assets/img/j42.png";
import memberSix from "../../assets/img/j43.png";
import memberSeven from "../../assets/img/j44.png";
import memberTen from "../../assets/img/j52.png";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { sendRequest } from "Api/Api";
import { ToastContainer } from "react-toastify";
import { HiOutlineWifi } from "react-icons/hi";
import { showSuccessAlert } from "components/Alerts/Alerts";
import { showErrorAlert } from "components/Alerts/Alerts";
import { CARD_OPTIONS } from "components/DynamicFuntions";
import { ActionAnimation } from "components/LottieAnimation/LottieAnimations";

const SubscriptionModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const stripe = useStripe();
  const elements = useElements();
  const [animationCheck, setAnimationCheck] = useState(false);
  function toggleModal() {
    setShowModal(!showModal);
  }
  console.log(props,"props values----->")

  useEffect(() => {
    if (props?.open === true) {
      toggleModal();
    }
  }, [props]);
  const handlePayment = async () => {
    setAnimationCheck(true);
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (!error) {
        try {
          const { id } = paymentMethod;
          const values = {
            senderId: userId.id,
            recieverId: props?.value?._id,
            payment: props?.value?.profilePrice
              ? props?.value?.profilePrice
              : 5,
            paymentId: id,
          };
          sendRequest(values).then((res) => {
            if (res.data.message === "payment Successful") {
              showSuccessAlert("payment Successful");
              toggleModal();
              window.location.reload(false);
            } else {
              setAnimationCheck(false);
              showErrorAlert("server error");
            }
          });
        } catch (error) {
          console.log("Error", error);
          setAnimationCheck(false);
        }
      }
    } catch (ex) {
      console.log(ex);
      setAnimationCheck(false);
    }
  };

  return (
    <div className="content">
      <div>
        <Button
          className="btn-sm mt-3 ml-2 profile-button"
          onClick={toggleModal}
        >
          <HiOutlineWifi
            className="mr-2 "
            style={{ fontSize: "20px", color: "white" }}
          />
          subscribe
        </Button>

        <Modal
          isOpen={showModal}
          toggle={toggleModal}
          className="main-modal"
          style={{ maxWidth: "400px", borderRadius: "10px" }}
        >
          <div className="modal-header"></div>
          <div className="modal-body home-modal">
            <Row className="" style={{ marginTop: "-20px" }}>
              <Col className="">
                <h4 className="text-white" style={{ fontWeight: "600" }}>
                  Pay for profile
                </h4>
              </Col>
              <Col className="text-right">
                <h4 className="text-white" style={{ fontWeight: "600" }}>
                  ${" "}
                  {props?.value?.profilePrice ? props?.value?.profilePrice : 5}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col className="">
                <Row className="">
                  <Col>
                    <input
                      type="radio"
                      name="radio-group"
                      className="ml-3"
                      aria-label="Radio button"
                    />
                    <img
                      src={memberTen}
                      className="img-fluid ml-3"
                      alt="stripe"
                    />
                    <span className="mt-2 ml-2" style={{ color: "#8B8B8B" }}>
                      Card
                    </span>
                  </Col>
                  <Col>
                    <img
                      src={memberFour}
                      className="img-fluid ml-3 mt-1 "
                      alt="stripe"
                    />
                    <img
                      src={memberFive}
                      className="img-fluid ml-3 mt-1 "
                      alt="stripe"
                    />
                    <img
                      src={memberSix}
                      className="img-fluid ml-3 mt-1"
                      alt="stripe"
                    />
                    <img
                      src={memberSeven}
                      className="img-fluid ml-3 mt-1"
                      alt="stripe"
                    />
                  </Col>
                </Row>
                <Label
                  for="exampleEmail"
                  className="mt-4"
                  style={{
                    color: "white",
                    fontWeight: "700",
                    fontSize: "15px",
                  }}
                >
                  Card number
                </Label>
                <CardElement options={CARD_OPTIONS} className="mt-2" />

                {animationCheck ? (
                  ActionAnimation()
                ) : (
                  <>
                    <h1 className="text-center mt-4 mb-0">
                      <Button
                        className="pay-btn reset-button"
                        onClick={handlePayment}
                      >
                        Submit
                      </Button>
                    </h1>
                    <h1 className="text-center mb-0">
                      <Button
                        type="button"
                        className="pay-cancel-btn"
                        onClick={toggleModal}
                      >
                        Cancel
                      </Button>
                    </h1>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};
export default SubscriptionModal;
