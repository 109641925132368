import { verifyCode } from "Api/Api";
import { updatePassword } from "Api/Api";
import { initiateEmailVerification } from "Api/Api";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import "./Modals.css";
import { showSuccessAlert } from "components/Alerts/Alerts";
import { showErrorAlert } from "components/Alerts/Alerts";
import { ActionAnimation } from "components/LottieAnimation/LottieAnimations";

const ForgetModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [passwordOne, setPasswordOne] = useState(true);
  const [passwordTwo, setPasswordTwo] = useState(false);
  const [passwordThree, setPasswordThree] = useState(false);
  const [animationCheck, setAnimationCheck] = useState(false);
  const [email, setEmail] = useState();
  const [code, setCode] = useState();
  const [password, setPassword] = useState();

  function toggleModal() {
    setShowModal(!showModal);
  }

  const sendingEmail = () => {
    setAnimationCheck(true);
    if (email) {
      const values = {
        email: email,
      };
      initiateEmailVerification(values).then((res) => {
        if (res.data.message === "email sent") {
          showSuccessAlert("code sent to given email");

          setPasswordOne(false);
          setPasswordTwo(true);
          setAnimationCheck(false);
        }
      });
    }
  };
  const verifyingCode = () => {
    setAnimationCheck(true);
    if (code) {
      const values = {
        code: code,
      };
      verifyCode(values).then((res) => {
        if (res.data.message === "Verification successful") {
          showSuccessAlert("Verification successfull");

          setPasswordTwo(false);
          setPasswordThree(true);
          setAnimationCheck(false);
        }
        if (res.data.message === "Verification invalid") {
          showSuccessAlert("Verification code is invalid or has expired");

          setAnimationCheck(false);
        }
      });
    }
  };
  const changePassword = async () => {
    setAnimationCheck(true);
    if (password) {
      const values = {
        password: password,
        email: email,
      };
      await updatePassword(values).then((res) => {
        if (res.data.message === "password updated") {
          showSuccessAlert("Password updated");

          toggleModal();
        }
        if (res.data.message === "email does not exist") {
          showErrorAlert("Server Error");
          setAnimationCheck(false);
          toggleModal();
        }
      });
    }
  };

  return (
    <div className="content">
      <div>
        <p
          className="login-end text-right mt-1"
          onClick={toggleModal}
          style={{ cursor: "pointer" }}
        >
          Forgot password?
        </p>

        <Modal
          isOpen={showModal}
          toggle={toggleModal}
          style={{ marginTop: "100px" }}
        >
          {passwordOne && (
            <>
              <div className="modal-header">
                <h4
                  className="text-white text-center"
                  style={{ fontWeight: "600" }}
                >
                  Reset Your Passoword
                </h4>
              </div>
              <hr
                style={{ backgroundColor: "#555555", marginTop: "-10px" }}
                className="mr-3 ml-3"
              />
              <div
                className="modal-body home-modal"
                style={{ borderRadius: "40px" }}
              >
                <FormGroup className="mt-4 mb-4" row>
                  <Label
                    for="exampleEmail"
                    sm={2}
                    style={{
                      color: "white",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    Email
                  </Label>
                  <Col sm={10}>
                    <Input
                      id="exampleEmail"
                      name="email"
                      type="email"
                      className="reset-input "
                      placeholder="Enter your email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Col>
                </FormGroup>
              </div>
              <hr
                style={{ backgroundColor: "#555555", marginTop: "-10px" }}
                className="mr-3 ml-3"
              />
              <Row className="justify-content-end">
                <Col xl={12} className="text-right">
                  {animationCheck ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {ActionAnimation()}
                    </div>
                  ) : (
                    <Button
                      type="button"
                      className="  reset-button mr-2 mb-2"
                      onClick={sendingEmail}
                    >
                      Continue
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          )}
          {passwordTwo && (
            <>
              <div className="modal-header">
                <h4
                  className="text-white text-center"
                  style={{ fontWeight: "600" }}
                >
                  Enter security code
                </h4>
              </div>
              <hr
                style={{ backgroundColor: "#555555", marginTop: "-10px" }}
                className="mr-3 ml-3"
              />
              <div
                className="modal-body home-modal"
                style={{ borderRadius: "40px" }}
              >
                <Row>
                  <Col>
                    <p
                      className="text-white mb-0"
                      style={{ fontSize: "10px", fontWeight: "600" }}
                    >
                      Please check your emails for a message with your code.Your{" "}
                    </p>
                    <p
                      className="text-white"
                      style={{ fontSize: "10px", fontWeight: "600" }}
                    >
                      code is 6 numbers long{" "}
                    </p>
                    <Row className="mt-3">
                      <Col>
                        <Input
                          id="exampleEmail"
                          name="email"
                          type="text"
                          className="reset-input"
                          placeholder="Enter Code"
                          onChange={(e) => setCode(e.target.value)}
                        />
                      </Col>
                      <Col className="text-right">
                        <p className="text-white ml-3 mb-0">
                          We sent your code to:
                        </p>
                        <span
                          className=" ml-3 "
                          style={{ color: "#BFB8B8", fontSize: "12px" }}
                        >
                          {email}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <hr
                style={{ backgroundColor: "#555555", marginTop: "-10px" }}
                className="mr-3 ml-3"
              />
              <Row className="justify-content-end">
                <Col xl={6}>
                  <a
                    className="ml-3 mt-3"
                    style={{ color: "#229ED9", fontSize: "10px" }}
                    onClick={sendingEmail}
                  >
                    Resend Code?
                  </a>
                </Col>

                <Col xl={6} className="text-right">
                  {animationCheck ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {ActionAnimation()}
                    </div>
                  ) : (
                    <Button
                      type="button"
                      className="  reset-button mr-2 mb-2"
                      onClick={verifyingCode}
                    >
                      Continue
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          )}
          {passwordThree && (
            <>
              <div className="modal-header">
                <h4
                  className="text-white text-center"
                  style={{ fontWeight: "600" }}
                >
                  Choose a new Password{" "}
                </h4>
              </div>
              <hr
                style={{ backgroundColor: "#555555", marginTop: "-10px" }}
                className="mr-3 ml-3"
              />
              <div
                className="modal-body home-modal"
                style={{ borderRadius: "40px" }}
              >
                <Row>
                  <Col>
                    <p
                      className="text-white mb-0"
                      style={{ fontSize: "10px", fontWeight: "600" }}
                    >
                      Create a new password that is at least 6 characters lonh.A
                      strong pssword is combineof{" "}
                    </p>
                    <p
                      className="text-white"
                      style={{ fontSize: "10px", fontWeight: "600" }}
                    >
                      letters,numbers,and punctuations marks.
                    </p>
                    <Row>
                      <Col xl={12}>
                        <FormGroup row className="mt-3">
                          <Label
                            for="exampleEmail"
                            sm={2}
                            xl={4}
                            style={{
                              color: "white",
                              fontWeight: "600",
                              fontSize: "15px",
                            }}
                          >
                            New Password
                          </Label>
                          <Col sm={5} xl={5}>
                            <Input
                              id="exampleEmail"
                              name="email"
                              placeholder=""
                              type="password"
                              className="reset-input"
                              style={{ marginLeft: "-11%", width: "111%" }}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <hr
                style={{ backgroundColor: "#555555", marginTop: "-10px" }}
                className="mr-3 ml-3"
              />
              <Row className="justify-content-end">
                <Col xl={12} className="text-right">
                  {animationCheck ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {ActionAnimation()}
                    </div>
                  ) : (
                    <Button
                      type="button"
                      className="  reset-button mr-2 mb-2"
                      onClick={changePassword}
                    >
                      Continue
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgetModal;
