import ChatPortion from "components/ChatPortion/ChatPortion";
import React, { useEffect,useState } from "react";
import { Button, Card, Col, Row } from "reactstrap";
import postOne from "../../assets/img/j46.png";
import FileBase64 from "react-file-base64";
import "./post.css";
import { createPost } from "Api/Api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getPosts } from "components/redux/actions/postActions";
import { useDispatch, useSelector } from "react-redux";
import { getUserById } from "components/redux/actions/userActions";
import { useHistory } from "react-router-dom";
import { uploadVideoToFirebase } from "components/DynamicFuntions";
import { uploadImageToFirebase } from "components/DynamicFuntions";
import { showErrorAlert } from "components/Alerts/Alerts";
import { showSuccessAlert } from "components/Alerts/Alerts";
import { showWarnAlert } from "components/Alerts/Alerts";
import { ActionAnimation } from "components/LottieAnimation/LottieAnimations";
import UserCheck from "components/UserCheck";

const CreatePost = () => {
  const [postPic, setPostPic] = useState();
  const [postCheck, setPostCheck] = useState(false);
  const [subscriberCheck, setSubscriberCheck] = useState(false);
  const [postVideoCheck, setPostVideoCheck] = useState(false);
  const [commentsCheck, setCommentsCheck] = useState(false);
  const [price, setPrice] = useState(0);
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [postUrl, setPostUrl] = useState();
  const [animationCheck, setAnimationCheck] = useState(false);
  const getUser = useSelector((state) => state?.getUserById);
  const userData = getUser?.userData;
  const history = useHistory();
  const dispatch = useDispatch();
  const Values = {
    userId: userId.id,
  };
  useEffect(() => {
    dispatch(getUserById(Values));
  }, []);

  const handlePostPic = async (e) => {
    setPostPic(e.selectedFile.base64);
    const type = e.selectedFile.base64.substring(5, 10);
    if (type === "image") {
      setPostVideoCheck(false);
      const result = await uploadImageToFirebase(e.selectedFile.base64);
      if (result) {
        setPostUrl(result);
      }
    } else if (type === "video") {
      setPostVideoCheck(true);
      const result = await uploadVideoToFirebase(e.selectedFile.base64);
      if (result) {
        setPostUrl(result);
      }
    } else {
      showErrorAlert("Server error");
    }
  };

  const handleCheckboxChange = () => {
    setPostCheck(!postCheck);
  };
  const handleSubscriberChange = () => {
    setSubscriberCheck(!subscriberCheck);
  };

  const post = async () => {
    setAnimationCheck(true);

    const values = {
      userId: userId.id,
      postPic: postUrl,
      postCheck: postCheck,
      commentsCheck: commentsCheck,
      price: price,
      postProfilePic: userData?.profilePic,
      userData: userData,
      subscriberCheck: subscriberCheck,
      key: "post",
    };

    if (postCheck === true) {
      if (price) {
        if (postUrl) {
          await createPost(values).then((res) => {
            if (res.data.message === "post Generated") {
              showSuccessAlert("Post Created");

              dispatch(getPosts());
              setTimeout(() => {
                history.push("/admin/home");
              }, 2000);
            } else {
              showErrorAlert("Server Error");
              setAnimationCheck(false);
            }
          });
        } else {
          setAnimationCheck(false);
          showWarnAlert("Please add video or photo");
        }
      } else {
        setAnimationCheck(false);
        showWarnAlert("Please add some price");
      }
    } else {
      if (postUrl) {
        await createPost(values).then((res) => {
          if (res.data.message === "post Generated") {
            showSuccessAlert("Post Created");

            dispatch(getPosts());
            setTimeout(() => {
              history.push("/admin/home");
            }, 2000);
          } else {
            showErrorAlert("Server Error");
            setAnimationCheck(false);
          }
        });
      } else {
        setAnimationCheck(false);
        showWarnAlert("Please add video or photo");
      }
    }
  };

  return (
    <div className="content">
      <Row>
        {userData?.creator === true && userData?.eroxrFee === true && (
          <Col xl={10}>
            <Row className="justify-content-center mr-lg-5">
              <Col xl={6} className="">
                <h2
                  className="text-center text-white"
                  style={{ fontWeight: "600" }}
                >
                  Create Post <br />
                  For Your Meeting
                </h2>

                <Card
                  className=" "
                  style={{
                    backgroundColor: "#1e1e2b",
                    borderRadius: "4px",
                    border: "1px solid white",
                  }}
                >
                  <h1 className="mt-5 mb-5 text-center">
                    <div
                      style={{
                        opacity: "0",
                        position: "absolute",
                        zIndex: "10",
                      }}
                    >
                      <FileBase64
                        type="file"
                        className="text-center"
                        onDone={(base64) =>
                          handlePostPic({ selectedFile: base64 })
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    {postVideoCheck === false && (
                      <img
                        src={postPic ? postPic : postOne}
                        alt=""
                        style={{ width: postPic && "80%", zIndex: "5" }}
                      />
                    )}

                    {postVideoCheck === true && (
                      <video
                        controls
                        src={postPic}
                        style={{ width: postPic && "80%", zIndex: "5" }}
                      />
                    )}
                  </h1>
                </Card>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control ad-input"
                    placeholder="Public or Private"
                    disabled={subscriberCheck}
                  />
                  <div className="input-group-append switch-input" class="switch-input">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={postCheck}
                        onChange={handleCheckboxChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control ad-input"
                    placeholder="Only for Subscribers"
                    disabled={postCheck}
                  />
                  <div className="input-group-append" class="switch-input">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={subscriberCheck}
                        onChange={handleSubscriberChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>

                {postCheck === true && (
                  <div className="input-group mt-3">
                    <input
                      type="text"
                      className="form-control ad-input"
                      placeholder="Price"
                    />
                    <div className="input-group-append switch-input switch-input">
                      <span
                        className="input-group-text counter-input"
                        id="counter"
                      >
                        <button
                          className="btn btn-sm  mr-1"
                          type="button"
                          id="btn-minus"
                          onClick={() => setPrice(price - 1)}
                        >
                          -
                        </button>
                        ${price}
                        <button
                          className="btn btn-sm  ml-1"
                          type="button"
                          id="btn-plus"
                          onClick={() => setPrice(price + 1)}
                        >
                          +
                        </button>
                      </span>
                    </div>
                  </div>
                )}

                <Row className="justify-content-end mt-3">
                  {animationCheck ? (
                    ActionAnimation()
                  ) : (
                    <Button className="reset-button mr-2" onClick={post}>
                      Submit
                    </Button>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        )}
        <UserCheck userData={userData}/>
        <Col xl={1}>
          <ChatPortion />
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default CreatePost;
