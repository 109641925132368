import { verifyCode } from "Api/Api";
import { initiateEmailVerification } from "Api/Api";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {ToastContainer } from "react-toastify";
import {
  Button,
  Input,
  Modal,
} from "reactstrap";
import "./Modals.css";
import { verifyStatus } from "Api/Api";
import { showSuccessAlert } from "components/Alerts/Alerts";

const SmsModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [phoneCheckOne, setPhoneCheckOne] = useState(true);
  const [phoneCheckTwo, setPhoneCheckTwo] = useState(false);
  const [userId, setuserId] = useState(
    JSON.parse(localStorage.getItem("keys"))
  );
  const [email, setEmail] = useState();
  const [code, setCode] = useState();

  const history = useHistory();

  function toggleModal() {
    setShowModal(!showModal);
  }

  const sendingCode = () => {
    const values = {
      email: email,
    };
    initiateEmailVerification(values).then((res) => {
      if (res.data.message === "email sent") {
        showSuccessAlert("code sent to given email");
        setPhoneCheckOne(false);
        setPhoneCheckTwo(true);
      }
    });
  };
  const verifyingCode = () => {
    const values = {
      code: code,
      userId: userId.id,
      verification: "verify",
    };
    const Values = {
      userId: userId.id,
    };
    verifyCode(values).then((res) => {
      if (res.data.message === "Verification successful") {
        verifyStatus(Values);
        showSuccessAlert("congratulations! your account is verified");
        toggleModal();
        setTimeout(() => {
          history.push("/admin/home");
        }, 2000);
      }
      if (res.data.message === "Verification invalid") {
        showSuccessAlert("Verification code is invalid or has expired");
      }
    });
  };

  return (
    <div className="content">
      <div>
        <Button
          className="auth-button"
          onClick={toggleModal}
          style={{ fontSize: "12px" }}
        >
          Use email (email)
        </Button>

        <Modal
          isOpen={showModal}
          toggle={toggleModal}
          className=""
          style={{ marginTop: "200px" }}
        >
          <div className="modal-body ">
            <h1 className="text-center text-white mb-0">Email Verification</h1>
            <hr style={{ backgroundColor: "#555555" }} className="mr-3 ml-1" />
            {phoneCheckOne && (
              <>
                <Input
                  id="exampleEmail"
                  name="email"
                  type="email"
                  className="reset-input"
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <h1 className="text-center">
                  <Button className="reset-button mt-3" onClick={sendingCode}>
                    Send Code
                  </Button>
                </h1>
              </>
            )}
            {phoneCheckTwo && (
              <>
                <Input
                  id="exampleEmail"
                  name="code"
                  type="text"
                  className="reset-input"
                  placeholder="Enter code"
                  onChange={(e) => setCode(e.target.value)}
                />
                <h1 className="text-center">
                  <Button
                    className="reset-button mt-3 mb-0"
                    onClick={verifyingCode}
                  >
                    Verify
                  </Button>
                </h1>
                <a
                  style={{ color: "#229ED9", cursor: "pointer" }}
                  onClick={sendingCode}
                >
                  Resend code
                </a>
              </>
            )}
          </div>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SmsModal;
