import * as actionTypes from '../constants';
import createAxiosInstance from 'Api/ApiInstance';
const api = createAxiosInstance();
// const url ="https://zany-lime-gharial-tam.cyclic.app/"
const url = 'http://localhost:5000';


export const getRequestById = (values) => async (dispatch) => {
    try {
        const { data } = await api.post(`${url}/getRequestById`,values);
        dispatch({ type: actionTypes.GET_ALL_REQUEST_SUCCESS, payload: data.data });
        console.log(data,"=========>requestAccptedData")

    } catch (error) {
        dispatch({ type: actionTypes.GET_ALL_REQUEST_FAIL, payload: error.response });
    }
};
export const getRequestBySenderId = (values) => async (dispatch) => {
    try {
        const { data } = await api.post(`${url}/getRequestBySenderId`,values);
        dispatch({ type: actionTypes.GET_ALL_SENDER_REQUEST_SUCCESS, payload: data.data });
        console.log(data,"=========>requestAccptedData")

    } catch (error) {
        dispatch({ type: actionTypes.GET_ALL_SENDER_REQUEST_FAIL, payload: error.response });
    }
};
export const getAllAcceptedUsers = (values) => async (dispatch) => {
    try {
        const { data } = await api.post(`${url}/getAllAcceptedUsers`,values);
        dispatch({ type: actionTypes.GET_ALL_ACCEPTED_REQUEST_SUCCESS, payload: data.data });
        console.log(data,"=========>requestData")

    } catch (error) {
        dispatch({ type: actionTypes.GET_ALL_ACCEPTED_REQUEST_FAIL, payload: error.response });
    }
};
export const getAllSubscriptions = () => async (dispatch) => {
    try {
        const { data } = await api.post(`${url}/getAllSubscriptions`);
        dispatch({ type: actionTypes.GET_ALL_SUBSCRIPTIONS_SUCCESS, payload: data.data });
        console.log(data,"=========>requestData")

    } catch (error) {
        dispatch({ type: actionTypes.GET_ALL_SUBSCRIPTIONS_FAIL, payload: error.response });
    }
};
export const AddUserToSubscription = (data)  => async (dispatch)=> {
    try {
        console.log(data,"in action========>")
        
         dispatch({ type: actionTypes.ADD_USER_TO_SUBSCRIBE, payload: data });
}
    
        // const { data } = await api.get(`${url}/getAllPost`);;
        // dispatch({ type: actionTypes.GET_POSTS_SUCCESS, payload: data });
        // console.log(data,"=========>data-redux")


     catch (error) {
        // dispatch({ type: actionTypes.GET_POSTS_FAIL, payload: error.response });
    }
};