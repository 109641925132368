import * as actionTypes from '../constants';
import createAxiosInstance from 'Api/ApiInstance';
const api = createAxiosInstance();
// const url ="https://zany-lime-gharial-tam.cyclic.app/"
const url = 'http://localhost:5000';


export const getContactById = (values) => async (dispatch) => {
    try {
        const { data } = await api.post(`${url}/getContactById`,values);
        dispatch({ type: actionTypes.GET_CONTACT_SUCCESS, payload: data.data });
        console.log(data,"=========>requestAccptedData")

    } catch (error) {
        dispatch({ type: actionTypes.GET_CONTACT_FAIL, payload: error.response });
    }
};