import React, { useState } from "react";
import { Card, CardBody, Table, Row, Col, Input, Container } from "reactstrap";
import { useEffect } from "react";
import { getAllUsers } from "components/redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getAllChatsById } from "Api/Api";
import { getContactById } from "components/redux/actions/contactActions";

const Chats = () => {
  const dispatch = useDispatch();
  const [friendsCheck, setFriendsCheck] = useState(false);
  const [messages, setMessages] = useState([]);
  const [senderId, setSenderId] = useState();
  const [senderName, setSenderName] = useState();
  const [recieverName, setRecieverName] = useState();
  const [recieverId, setRecieverId] = useState();
  const [chatCheck, setChatCheck] = useState(false);
  const [filterUser, setFilterUser] = useState([]);
  const [readChats, setReadChats] = useState();
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);
  const getAllUser = useSelector((state) => state?.getAllUsers);
  const AllUser = getAllUser?.allUsers;

  const getAllAcceptedRequests = useSelector(
    (state) => state?.getContactById?.contactById
  );
  useEffect(() => {
    setReadChats(getAllAcceptedRequests);
  }, [getAllAcceptedRequests]);

  const getFriends = (e, name) => {
    setReadChats(null);
    const values = {
      userId: e,
    };
    dispatch(getContactById(values));
    setSenderName(name);
    setFriendsCheck(true);
    setSenderId(e);
  };
  const getChats = (e, name) => {
    setRecieverName(name);
    setRecieverId(e);
    const values = {
      senderId: senderId,
      recieverId: e,
    };
    getAllChatsById(values).then((res) => {
      setMessages(res?.data?.data);
    });
    setChatCheck(true);
  };

  console.log(messages,"messages coming------->")
  function renderImageTagSecond(imageString) {
    let blobUrlPattern =
      /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;

    const url = blobUrlPattern.test(imageString);

    if (url) {
      return <img src={imageString} alt="" />;
    } else {
      console.log("no url");
      return (
        <>
          <Input
            defaultValue=""
            placeholder="Type your message here..."
            type="text"
            value={imageString}
            className="chat-second-inputs mt-3 "
          />
        </>
      );
    }
  }
  function renderImageTag(imageString) {
    let blobUrlPattern =
      /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;

    const url = blobUrlPattern.test(imageString);

    if (url) {
      return <img src={imageString} alt="" />;
    } else {
      console.log("no url");
      return (
        <>
          <Input
            defaultValue=""
            placeholder="Type your message here..."
            type="text"
            value={imageString}
            className="chat-inputs mt-3 "
          />
        </>
      );
    }
  }
  useEffect(() => {
    setFilterUser(AllUser?.filter((user) => user?.key !== "admin"));
  }, [AllUser]);
  return (
    <>
      <div className="content">
        {chatCheck === false && friendsCheck ? (
          <>
            <h3 className="ml-lg-3">{senderName + "'s"} Subscribers</h3>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <Table className="tablesorter user-table">
                      <thead className="text-primary">
                        <tr>
                          <th className="text-center">First Name</th>
                          <th className="text-center">Last Name</th>
                          <th className="text-center">Username</th>
                          <th className="text-center">Phone Number</th>
                          <th className="text-center">Email</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {readChats?.map((data) => (
                          <tr>
                            <td className="text-center">{data?.firstName}</td>
                            <td className="text-center">{data?.lastName}</td>
                            <td className="text-center">
                              {data?.username ? data?.username : "-"}
                            </td>
                            <td className="text-center">
                              {data?.phoneNumber ? data?.phoneNumber : "-"}
                            </td>
                            <td className="text-center">{data?.email}</td>
                            <td className="text-center">
                              <span
                                onClick={() =>
                                  getChats(data?._id, data?.firstName)
                                }
                                style={{ color: "blue", cursor: "pointer" }}
                              >
                                view chats
                              </span>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        ) : (
          chatCheck === false && (
            <>
              <h2 className="ml-lg-3">Chats</h2>
              <Row>
                <Col md="12">
                  <Card>
                    <CardBody>
                      <Table className="tablesorter user-table">
                        <thead className="text-primary">
                          <tr>
                            <th className="text-center">First Name</th>
                            <th className="text-center">Last Name</th>
                            <th className="text-center">Username</th>
                            <th className="text-center">Phone Number</th>
                            <th className="text-center">Email</th>
                            <th className="text-center">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterUser?.map((data) => (
                            <tr>
                              <td className="text-center">{data?.firstName}</td>
                              <td className="text-center">{data?.lastName}</td>
                              <td className="text-center">
                                {data?.username ? data?.username : "-"}
                              </td>
                              <td className="text-center">
                                {data?.phoneNumber ? data?.phoneNumber : "-"}
                              </td>
                              <td className="text-center">{data?.email}</td>
                              <td className="text-center">
                                <span
                                  onClick={() =>
                                    getFriends(data?._id, data?.firstName)
                                  }
                                  style={{ color: "blue", cursor: "pointer" }}
                                >
                                  view subscribers
                                </span>{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )
        )}
        {chatCheck === true && (
          <>
            <h3 className="text-center">
              {senderName + "'s"} chat with {recieverName}
            </h3>
            <div className="ml-lg-5">
              <Container>
                <Row className="ml-lg-5">
                  <Col className="ml-lg-5">
                    <div className="msg-div-two">
                      {messages ? (
                        messages?.map((data, index) => (
                          <div>
                            {data?.senderId === senderId ? (
                              <>{renderImageTag(data?.message)}</>
                            ) : (
                              <>
                                <Row
                                  className=""
                                  style={{ marginLeft: "380px" }}
                                  data-aos="fade-up"
                                >
                                  {renderImageTagSecond(data?.message)}
                                </Row>
                              </>
                            )}
                          </div>
                        ))
                      ) : (
                        <div className="loading">Loading chats...</div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        )}

        <ToastContainer />
      </div>
    </>
  );
};

export default Chats;
